import { Entity } from '../../@Api/Model/Implementation/Entity';
import { identify, Identify, track } from '@amplitude/analytics-browser';
import { loadModuleDirectly } from '../DependencyInjection/index';
import { CurrentUserStore } from '../../@Component/Domain/User/CurrentUserStore';
import { GlobalEnvironment } from '../../@Global/GlobalEnvironment';
import { SettingSource, SettingStore } from '../../@Component/Domain/Setting/SettingStore';
import { Setting } from '../../@Api/Settings/Setting';
import getAmplitudeUserId from './getAmplitudeUserId';
import getIntercomUserId from './getIntercomUserId';

export enum EventTypes {
    AppLaunch = "[Efficy] App Launch",
    Login = "[Efficy] Login",
    LoginError = "[Efficy] Login Error",
    Logout = "[Efficy] Logout",
    LogoutError = "[Efficy] Logout Error",
    ConsultRecord = "[Efficy] Consult Record",
    StartCreateRecord = "[Efficy] Start Create Record",
    FinishCreateRecord = "[Efficy] Finish Create Record",
    AbortCreateRecord = "[Efficy] Abort Create Record",
    NewUIEnabled = "[Efficy] New UI activated",
    NewUIDisabled = "[Efficy] New UI deactivated",
}

export default function sendAnalyticsLogging(
    eventType: EventTypes,
    entity?: Entity,
    settingStore?: SettingStore
)
{
    try
    {
    if (!GlobalEnvironment.AMPLITUDE_API_KEY || GlobalEnvironment.AMPLITUDE_API_KEY.length ===0)
    {
        return
        }

        const currentUserStore = loadModuleDirectly(CurrentUserStore);
        const settingStoreStore = settingStore || loadModuleDirectly(SettingStore);

        const v2Enabled = settingStoreStore.getValue(SettingSource.User, Setting.RedirectToV2);

        let eventProperties = null;

        if (entity != null)
        {
            eventProperties = {
                '[Efficy] Object Key': entity.id.toString(),
                '[Efficy] Object Type': entity.entityType.getName()
            };
        }

        const event = {
            event_type: eventType,
            event_properties: eventProperties,
            user_id: getAmplitudeUserId(currentUserStore)
        }

        track(event);

        if (currentUserStore)
        {
            const identifyEvent = new Identify();
            identifyEvent.set('[Efficy] Customer Name', currentUserStore.currentOrganization?.name);
            identifyEvent.set('[Efficy] Customer Key', currentUserStore.currentOrganization?.id.toString());
            identifyEvent.set('[Efficy] User Key', currentUserStore.employeeEntity.id.toString());
            identifyEvent.set('[Efficy] Account Key', currentUserStore.currentAccount?.id.toString());
            identifyEvent.set('[Efficy] Product Name', 'Tribe CRM');
            identifyEvent.set('[Efficy] New UI', v2Enabled);
            identifyEvent.set('[Efficy] Intercom User ID', getIntercomUserId(currentUserStore));

            identify(identifyEvent);
        }
    }
    catch (e)
    {
        console.error(e.message);
    }
}
import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core";
import { Draggable as DraggableDnd } from "react-beautiful-dnd";
import DragHandlePropsContext from "../../../../../../../DragAndDrop/Context/DragHandlePropsContext";
import DraggableStateContext from "../../../../../../../DragAndDrop/Context/DraggableStateContext";
import TableRow from "@material-ui/core/TableRow";
import { classNames } from "../../../../../../../../../@Future/Util/Class/classNames";
import TableCell from "@material-ui/core/TableCell";
import DragHandle from "../../../../../../../DragAndDrop/DragHandle/DragHandle";
import Checkbox from "../../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox";
import ViewGroup from "../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup";
import ViewGroupItem from "../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem";
import ProductLineSubscriptionEditor from "./ProductLineSubscriptionEditor/ProductLineSubscriptionEditor";
import { default as GenericInput } from "../../../../../../../../../@Future/Component/Generic/Input/Input/Input";
import LocalizedText from "../../../../../../../Localization/LocalizedText/LocalizedText";
import ButtonGroup from "../../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/ButtonGroup";
import IconButton from "../../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton";
import { primaryColor, textSecondaryColor } from "../../../../../../../../../@Resource/Theme/Theme";
import MenuButton from "../../../../../../Item/MenuButton/MenuButton";
import ProductLineBilledTimeRegistrations from "./ProductLineBilledTimeRegistrations/ProductLineBilledTimeRegistrations";
import ProductLineBilledMileageRegistrations from "./ProductLineBilledMileageRegistrations/ProductLineBilledMileageRegistrations";
import useTypes from "../../../../../../Type/Api/useTypes";
import useProductLine from "./Hooks/useProductLine";
import CurrentUserContext from "../../../../../../../User/CurrentUserContext";
import useIsDragging from "../../../../../../../DragAndDrop/Api/useIsDragging";
import useToggle from "../../../../../../../../../@Util/Toggle/useToggle";
import { ProductLineProps } from "./DesktopProductLine";
import { ProductLineDescription } from "./Components/ProductLineDescription";
import { ProductLineQuantity } from "./Components/ProductLineQuantity";
import { ProductLineStockLevel } from "./Components/ProductLineStockLevel";
import { ProductLinePrice } from "./Components/ProductLinePrice";
import { ProductLinePurchasePrice } from "./Components/ProductLinePurchasePrice";
import { ProductLineVatGroup } from "./Components/ProductLineVatGroup";
import { ProductLineDiscountPercentage } from "./Components/ProductLineDiscountPercentage";
import { ProductLineTotal } from "./Components/ProductLineTotal";
import { ProductLineMilestone } from "./Components/ProductLineMilestone";
import { ProductLineLayoutEditor } from "./Components/ProductLineLayoutEditor";
import { Alignment } from "../../../../../../../DataObject/Model/DataObject";
import { ProductLineExtraDescription } from "./Components/ProductLineExtraDescription";

const useStyles = makeStyles({
    noPadding:
    {
        padding: '3px !important'
    },
    dragging:
    {
        display: 'none', // : 'hidden',
        background: 'red'
    },
    strikeThrough:
    {
        textDecoration: 'line-through'
    },
    extraDescriptionRow:
        {
            "& td":
                {
                    whiteSpace: 'normal !important',
                    paddingTop: 0
                }
        },
});

export const TabletProductLine: React.FC<ProductLineProps> = observer(
    ({
        idx,
        entity,
        commitContext,
        onSelected,
        isSelected,
        isStrikethroughed,
        showMilestone,
        hidePrices,
        loading,
        currency,
        disabled,
        onChangeSpecificationLayout,
        isLoadingSpecificationLayout,
        specificationLayout,
        specificationLayoutParameters,
        specificationLayoutParameterAssignment,
        autoCommit
    }) =>
    {
        const types = useTypes();
        const model =
            useProductLine(
                entity,
                commitContext,
                onSelected,
                isSelected,
                isStrikethroughed,
                loading,
                currency,
                disabled,
                autoCommit
            );
        const currentUserStore = useContext(CurrentUserContext);
        const classes = useStyles();
        const isDragging = useIsDragging();
        const [ showBilledTimeRegistrations, toggleShowBilledTimeRegistrations ] = useToggle(false);
        const [ showBilledMileageRegistrations, toggleShowBilledMileageRegistrations ] = useToggle(false);
        const [ isSpecificationLayoutInEditMode, setSpecificationLayoutEditMode ] = useState(false);

        return <DraggableDnd
            draggableId={model.uuid}
            index={idx}
            isDragDisabled={disabled}
        >
            {
                (provided, snapshot) =>
                    <DragHandlePropsContext.Provider
                        value={provided.dragHandleProps}
                    >
                        <DraggableStateContext.Provider
                            value={snapshot}
                        >
                            <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={classNames(model.isStrikethrough && classes.strikeThrough)}
                            >
                                <TableCell
                                    rowSpan={
                                        isDragging
                                            ? 1
                                            : model.description.isExtraDescriptionVisible ? 3 : 2
                                    }
                                    className={
                                        classNames(
                                            classes.extraDescriptionRow,
                                            classes.noPadding)
                                     }
                                >
                                    <DragHandle/>
                                </TableCell>
                                {
                                    onSelected &&
                                    <TableCell
                                        rowSpan={
                                            isDragging
                                                ? 1
                                                : model.description.isExtraDescriptionVisible ? 3 : 2
                                        }
                                        className={
                                            classNames(
                                                classes.extraDescriptionRow,
                                                classes.noPadding)
                                        }
                                    >
                                        {
                                            !disabled &&
                                            <Checkbox
                                                checked={isSelected}
                                                onToggle={model.onInvoice}
                                            />
                                        }
                                    </TableCell>
                                }
                                <TableCell colSpan={6}>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={0}
                                    >
                                        <ViewGroupItem>
                                            <ProductLineDescription
                                                productLine={model}
                                                disabled={disabled}
                                            />
                                        </ViewGroupItem>
                                        {
                                            model.isSubscriptionLine &&
                                            <ViewGroupItem>
                                                <ProductLineSubscriptionEditor
                                                    entity={model.productLine}
                                                    disabled={disabled}
                                                    commitContext={model.commitContext}
                                                />
                                            </ViewGroupItem>
                                        }
                                    </ViewGroup>
                                </TableCell>
                                <TableCell>
                                    <ButtonGroup
                                        noWrap
                                    >
                                        {
                                            types.ProductLine.RelationshipDefinition.BilledTimeRegistrations &&
                                            model.isParentActivityAnInvoiceOrSalesOrderOrProject &&
                                            <IconButton
                                                icon="timer"
                                                onClick={toggleShowBilledTimeRegistrations}
                                                color={showBilledTimeRegistrations ? primaryColor : textSecondaryColor}
                                                tooltip={types.ProductLine.RelationshipDefinition.BilledTimeRegistrations.getName(false)}
                                            />
                                        }
                                        {
                                            types.ProductLine.RelationshipDefinition.BilledMileageRegistrations &&
                                            model.isParentActivityAnInvoiceOrSalesOrderOrProject &&
                                            <IconButton
                                                icon="directions_car"
                                                onClick={toggleShowBilledMileageRegistrations}
                                                color={showBilledMileageRegistrations ? primaryColor : textSecondaryColor}
                                                tooltip={types.ProductLine.RelationshipDefinition.BilledMileageRegistrations.getName(false)}
                                            />
                                        }
                                        <MenuButton
                                            entity={model.productLine}
                                        >
                                            <GenericInput
                                                labelPosition="right"
                                                label={types.ProductLine.Field.ExtraDescription.getName()}
                                            >
                                                <Checkbox
                                                    checked={model.description.isExtraDescriptionVisible}
                                                    onToggle={model.description.setExtraDescriptionVisible}
                                                />
                                            </GenericInput>
                                            {
                                                currentUserStore.isAdministrator &&
                                                <GenericInput
                                                    labelPosition="right"
                                                    label={
                                                        <LocalizedText
                                                            code="ProductLine.EditSpecificationLayout"
                                                            value="Specificatie layout wijzigen"
                                                        />
                                                    }
                                                >
                                                    <Checkbox
                                                        checked={isSpecificationLayoutInEditMode}
                                                        onToggle={setSpecificationLayoutEditMode}
                                                    />
                                                </GenericInput>
                                            }
                                        </MenuButton>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow
                                className={
                                    classNames(
                                        isDragging && classes.dragging
                                    )
                                }
                            >
                            <TableCell
                                align="right"
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                >
                                    <ViewGroupItem>
                                        <ProductLineQuantity
                                            productLine={model}
                                            alignment={Alignment.Right}
                                        />
                                    </ViewGroupItem>
                                    <ProductLineStockLevel
                                        productLine={model}
                                    />
                                </ViewGroup>
                            </TableCell>
                            <TableCell
                                align="right"
                            >
                                {
                                    !hidePrices &&
                                    <ProductLinePrice
                                        productLine={model}
                                        currency={currency}
                                        autoCommit={autoCommit}
                                        alignment={Alignment.Right}
                                    />
                                }
                            </TableCell>
                            <TableCell
                                align="right"
                            >
                                {
                                    !hidePrices &&
                                    <ProductLinePurchasePrice
                                        productLine={model}
                                        autoCommit={autoCommit}
                                        disabled={disabled}
                                        alignment={Alignment.Right}
                                    />
                                }
                            </TableCell>
                            <TableCell
                                align="right"
                            >
                                {
                                    !hidePrices &&
                                    <ProductLineVatGroup
                                        productLine={model}
                                        autoCommit={autoCommit}
                                        alignment={Alignment.Right}
                                    />
                                }
                            </TableCell>
                            <TableCell
                                align="right"
                            >
                                {
                                    !hidePrices &&
                                    <ProductLineDiscountPercentage
                                        productLine={model}
                                        autoCommit={autoCommit}
                                        alignment={Alignment.Right}
                                    />
                                }
                            </TableCell>
                            <TableCell
                                align="right"
                            >
                                {
                                    !hidePrices &&
                                    <ProductLineTotal
                                        productLine={model}
                                        autoCommit={autoCommit}
                                        currency={currency}
                                        alignment={Alignment.Right}
                                    />
                                }
                            </TableCell>
                            {
                                showMilestone &&
                                <TableCell>
                                    <ProductLineMilestone
                                        productLine={model}
                                        disabled={disabled}
                                        autoCommit={autoCommit}
                                    />
                                </TableCell>
                            }
                            <TableCell />
                        </TableRow>
                            {
                                model.description.isExtraDescriptionVisible &&
                                <TableRow
                                    className={
                                        classNames(
                                            classes.extraDescriptionRow,
                                            isDragging && classes.dragging
                                        )
                                    }
                                >
                                    <TableCell
                                        colSpan={showMilestone ? 7 : 6}
                                    >
                                        <ProductLineExtraDescription
                                            productLine={model}
                                            disabled={disabled}
                                        />
                                    </TableCell>
                                </TableRow>
                            }
                            <ProductLineLayoutEditor
                                productLine={model}
                                isDragging={isDragging}
                                onChangeSpecificationLayout={onChangeSpecificationLayout}
                                isLoadingSpecificationLayout={isLoadingSpecificationLayout}
                                specificationLayout={specificationLayout}
                                onSelected={onSelected}
                                specificationLayoutParameterAssignment={specificationLayoutParameterAssignment}
                                showMilestone={showMilestone}
                                specificationLayoutParameters={specificationLayoutParameters}
                                isSpecificationLayoutInEditMode={isSpecificationLayoutInEditMode}
                                setSpecificationLayoutEditMode={setSpecificationLayoutEditMode}
                            />
                            {
                                showBilledTimeRegistrations &&
                                <TableRow
                                    className={isDragging && classes.dragging}
                                >
                                    <TableCell
                                        colSpan={(showMilestone ? 1 : 0) + (onSelected ? 2 : 1) + 8}
                                    >
                                        <ProductLineBilledTimeRegistrations
                                            productLine={model.productLine}
                                        />
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                showBilledMileageRegistrations &&
                                <TableRow
                                    className={isDragging && classes.dragging}
                                >
                                    <TableCell
                                        colSpan={(showMilestone ? 1 : 0) + (onSelected ? 2 : 1) + 8}
                                    >
                                        <ProductLineBilledMileageRegistrations
                                            productLine={model.productLine}
                                        />
                                    </TableCell>
                                </TableRow>
                            }
                        </DraggableStateContext.Provider>
                    </DragHandlePropsContext.Provider>
            }
        </DraggableDnd>;
    }
);
import { User } from '../../../@Api/Model/Implementation/User';
import { Account } from '../../../@Api/Model/Implementation/Account';
import { action, computed, observable, runInAction } from 'mobx';
import { EntityTypeStore } from '../Entity/Type/EntityTypeStore';
import { injectWithQualifier, loadModuleDirectly } from '../../../@Util/DependencyInjection/index';
import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { Entity } from '../../../@Api/Model/Implementation/Entity';
import { RightProfile } from '../Entity/Right/RightProfile';
import { FileValue } from '../DataObject/Type/File/FileValue';
import { WelcomeStore } from '../../../@Service/Welcome/WelcomeStore';
import * as Sentry from '@sentry/browser';
import { EntityType } from '../../../@Api/Model/Implementation/EntityType';
import Input from '../Multiplayer/Model/Input/Input';
import { SettingSource, SettingStore } from '../Setting/SettingStore';
import resolveInputFromDescriptor from '../Multiplayer/Model/Input/Api/resolveInputFromDescriptor';
import { EntitySelectionBuilder } from '../Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../Entity/Path/@Model/EntityPath';
import { EntitySelectionResult } from '../Entity/Selection/Model/EntitySelectionResult';
import Role from '../Role/Model/Role';
import { AuthenticationManager } from '../../../@Service/Authentication/AuthenticationManager';
import { Organization } from '../../../@Api/Model/Implementation/Organization';
import DefaultWorkspaceBackground from '../../../@Resource/Image/Background/DefaultWorkspaceBackground.jpg';
import { SettingController } from '../../../@Api/Controller/Directory/SettingController';
import { LocalizationStore } from '../../../@Service/Localization/LocalizationStore';
import { ApiControllerStore } from '../../../@Api/Controller/ApiControllerStore';
import { GlobalEnvironment } from '../../../@Global/GlobalEnvironment';
import { Edition } from '../License/Model/Edition';
import { createStringComparator } from '../../Generic/List/V2/Comparator/StringComparator';
import sendAnalyticsLogging, { EventTypes } from '../../../@Util/Analytics/sendAnalyticsLogging';

export class CurrentUserStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('WelcomeStore') welcomeStore: WelcomeStore;
    @injectWithQualifier('SettingStore') settingStore: SettingStore;
    @injectWithQualifier('SettingController') settingController: SettingController;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('ApiControllerStore') apiControllerStore: ApiControllerStore;

    // ------------------------- Properties -------------------------

    @observable.ref currentUser: User;
    @observable.ref currentTeam: Entity;
    @observable.ref currentContract: Entity;
    @observable.ref rightProfile: RightProfile;
    @observable.shallow limitedAccessTeamResults: EntitySelectionResult[];

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        const user = this.welcomeStore.welcomePackage.user;
        user.initialize(this.entityTypeStore);

        return this.setCurrentUser(user).then(
            () =>
            {
                this.setLanguage(this.welcomeStore.welcomePackage.languagePackage.languageCode);

                const team = this.welcomeStore.welcomePackage.team;
                this.currentTeam = team?.initialize(undefined, undefined, undefined, undefined, undefined, undefined, true);

                const contract = this.welcomeStore.welcomePackage.contract;
                this.currentContract = contract?.initialize(undefined, undefined, undefined, undefined, undefined, undefined, true);

                Sentry.configureScope(
                    scope =>
                    {
                        if (this.currentAccount)
                        {
                            scope.setUser({
                                email: this.currentAccount.username
                            });
                        }

                        if (this.environmentEntity)
                        {
                            scope.setTag('environment_id', this.environmentEntity.uuid);
                            scope.setTag('environment_name', this.environmentEntity.name);
                        }
                    });

                return new EntitySelectionBuilder(this.entityTypeStore.bespoke.types.Team.Type)
                    .where(
                        cb =>
                            cb.eq(
                                EntityPath.fromEntityType(this.entityTypeStore.bespoke.types.Team.Type)
                                    .field(this.entityTypeStore.bespoke.types.Team.Field.IsAccessLimitedToTeam),
                                undefined,
                                true))
                    .orderBy(
                        EntityPath.fromEntityType(this.entityTypeStore.bespoke.types.Team.Type)
                            .field(this.entityTypeStore.bespoke.types.Team.Field.LocalizedName),
                        true
                    )
                    .select()
                    .then(
                        teamResults =>
                            runInAction(
                                () =>
                                    this.limitedAccessTeamResults = teamResults));
            }
        )
        .then(
            () =>
                sendAnalyticsLogging(
                    EventTypes.Login,
                    null,
                    this.settingStore
                )
        );
    }

    reloadUser()
    {
        return loadModuleDirectly(AuthenticationManager)
            .switchToUser(this.currentUser)
            .then(
                () =>
                    sendAnalyticsLogging(
                        EventTypes.Login,
                        null,
                        this.settingStore
                    )
            );
    }

    // -------------------------- Computed --------------------------

    @computed
    get currentOrganization(): Organization
    {
        return this.currentUser.organization;
    }

    @computed
    get currentAccount(): Account
    {
        return this.currentUser.account;
    }

    @computed
    get employeePath()
    {
        return this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Employee.Type.path();
    }

    @computed
    get environmentPath()
    {
        return this.employeePath
            .joinTo(
                this.entityTypeStore.bespoke.types.Relation.RelationshipDefinition.Relationships,
                true);
    }

    @computed
    get environmentPackPath()
    {
        return this.environmentPath
            .joinTo(
                this.entityTypeStore.bespoke.types.Pack.RelationshipDefinition.Entities,
                true);
    }

    @computed
    get accountPath()
    {
        return this.employeePath
            .joinTo(
                this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person,
                false);
    }

    @computed
    get ownedPacksPath()
    {
        return this.accountPath
            .joinTo(
                this.entityTypeStore.bespoke.types.Relation.Person.Account.RelationshipDefinition.Packs,
                false);
    }

    @computed
    get environmentEntity(): Entity
    {
        if (this.employeeEntity)
        {
            return this.environmentPath.traverseEntity(this.employeeEntity).find(() => true);
        }
        else
        {
            // Should never happen, will cause NPE
            return undefined;
        }
    }

    @computed
    get environmentPackEntity(): Entity
    {
        if (this.environmentEntity)
        {
            return this.environmentEntity.getRelatedEntityByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Pack.RelationshipDefinition.Entities);
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get userPackEntity(): Entity
    {
        if (this.employeeEntity)
        {
            return this.employeeEntity.getRelatedEntityByDefinition(
                false,
                this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Employee.RelationshipDefinition.Pack);
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get contractEntity(): Entity
    {
        return this.currentContract;
    }

    @computed
    get contractEdition(): Edition
    {
        const edition = this.contractEntity?.getRelatedEntityByDefinition(
            false,
            this.entityTypeStore.bespoke.types.LicenseContract.RelationshipDefinition.LicenseContractEdition
        );

        return edition?.getObjectValueByField(this.entityTypeStore.bespoke.types.Datastore.Field.Code);
    }

    @computed
    get employeeEntity(): Entity
    {
        if (this.currentUser)
        {
            return this.currentUser.entity;
        }
        else
        {
            // Should never happen, will cause NPE
            return undefined;
        }
    }

    @computed
    get employeeSetting(): Entity
    {
        return this.employeeEntity.getRelatedEntityByDefinition(
            false,
            this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Employee.RelationshipDefinition.Settings);
    }

    @computed
    get accountEntity(): Entity
    {
        if (this.employeeEntity)
        {
            return this.accountPath.traverseEntity(this.employeeEntity).find(() => true);
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get ownedPackEntities(): Entity[]
    {
        if (this.employeeEntity)
        {
            return this.ownedPacksPath
                .traverseEntity(
                    this.employeeEntity);
        }
        else
        {
            return [];
        }
    }

    @computed
    get packs(): Entity[]
    {
        return this.environmentEntity.getRelatedEntitiesByDefinition(
            false,
            this.entityTypeStore.bespoke.types.Relation.Organization.Environment.RelationshipDefinition.Packs);
    }

    @computed
    get teams(): Entity[]
    {
        return this.employeeEntity.getRelatedEntitiesByDefinition(
            true,
            this.entityTypeStore.bespoke.types.Team.RelationshipDefinition.Members)
            .sort(
                createStringComparator(team =>
                    team.getName())
            );
    }

    @computed
    get limitedAccessTeams(): Entity[]
    {
        return this.teams
            .filter(
                team =>
                    team.getObjectValueByField(this.entityTypeStore.bespoke.types.Team.Field.IsAccessLimitedToTeam));
    }

    @computed
    get allLimitedAccessTeams()
    {
        return this.limitedAccessTeamResults
            .map(
                team =>
                    team.entity);
    }

    @computed
    get isMainDeveloper(): boolean
    {
        return this.currentAccount.username.toLowerCase() === 'daniel.duwaer@intentic.com'
            || this.currentAccount.username.toLowerCase() === 'maikel.schepens@perfectview.nl'
            || this.currentAccount.username.toLowerCase() === 'lucas.duwaer@intentic.com'
            || this.currentAccount.username.toLowerCase() === 'lucas@duwaer.nl';
    }

    @computed
    get isDeveloper(): boolean
    {
        return this.currentAccount.isDeveloper;
    }

    @computed
    get isConsultant(): boolean
    {
        return this.currentAccount.isConsultant
            && this.employeeEntity.getObjectValueByField(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Employee.Field.IsSupport);
    }

    @computed
    get isSupport(): boolean
    {
        return this.isDeveloper || this.isConsultant;
    }

    @computed
    get isAdministrator(): boolean
    {
        return this.employeeEntity.getObjectValueByField(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Employee.Field.IsAdministrator)
            || this.isSupport;
    }

    @computed
    get isPrototypingTeam(): boolean
    {
        return this.isDeveloper
            || this.currentAccount?.username === 'ingrid.peters@perfectview.nl'
            || this.currentAccount?.username === 'edwin.somhorst@perfectview.nl';
    }

    @computed
    get isEarlyBird(): boolean
    {
        return this.contractEdition === 'EarlyBird';
    }

    @computed
    get isNonEssentialEdition(): boolean
    {
        return this.isSupport
            || (this.contractEdition && this.contractEdition !== 'Essential');
    }

    @computed
    get isAllowedInConfiguration(): boolean
    {
        return this.isAdministrator;
    }

    @computed
    get isAllowedInViewConfiguration(): boolean
    {
        if (this.isAllowedInConfiguration)
        {
            return true;
        }
        else
        {
            return this.rightProfile.canCreateType(this.entityTypeStore.bespoke.types.View.Type)
                || this.rightProfile.canUpdateType(this.entityTypeStore.bespoke.types.View.Type)
                || this.rightProfile.canDeleteType(this.entityTypeStore.bespoke.types.View.Type);
        }
    }

    @computed
    get isAllowedInDatasetConfiguration(): boolean
    {
        if (this.isAllowedInConfiguration)
        {
            return true;
        }
        else
        {
            return this.rightProfile.canCreateType(this.entityTypeStore.bespoke.types.Dataset.Type)
                || this.rightProfile.canUpdateType(this.entityTypeStore.bespoke.types.Dataset.Type)
                || this.rightProfile.canDeleteType(this.entityTypeStore.bespoke.types.Dataset.Type);
        }
    }

    @computed
    get wallpaperImageUrl(): string
    {
        if (this.accountEntity)
        {
            const fileValue = this.accountEntity.getDataObjectValueByField(this.entityTypeStore.bespoke.types.Relation.Person.Field.WorkspaceBackground);

            if (!fileValue.isEmpty)
            {
                return fileValue.context.getFileUrl((fileValue.value as FileValue).url);
            }
        }

        return `${GlobalEnvironment.APP_ENDPOINT}/${DefaultWorkspaceBackground}`;
    }

    @computed
    get typeNameOverrides(): Map<EntityType, Input>
    {
        return new Map(
            Object.entries(this.settingStore.getValue(SettingSource.Organization, 'Metadata.TypeNameOverrides'))
                .map(
                    ([ key, value ]) => [
                        this.entityTypeStore.getTypeById(parseInt(key)),
                        resolveInputFromDescriptor(value)
                    ]));
    }

    @computed
    get packCodes()
    {
        return new Set(
            this.packs
                .map(
                    pack =>
                        pack.getObjectValueByField(this.entityTypeStore.bespoke.types.Pack.Field.Code))
                .filter(
                    packCode =>
                        packCode !== undefined));
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    async setCurrentUser(user: User)
    {
        this.currentUser = user;

        return await Promise.resolve(
            this.apiControllerStore.accountUserController.getRole()
            .then(
                async roleDescriptor =>
                {
                    this.rightProfile =
                        RightProfile.fromEntities(
                            this.ownedPackEntities,
                            this.environmentPackEntity,
                            this.userPackEntity,
                            await Role.fromDescriptor(roleDescriptor),
                            this.entityTypeStore,
                            this
                        );
                }
            )
        );
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    setLanguage(languageCode: string)
    {
        document.documentElement.lang = languageCode;
    }
}

import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { ProductLineEditorInterface } from "../Hooks/useProductLine";
import Input from "../../../../../../../Input/Input";
import { Alignment } from "../../../../../../../../DataObject/Model/DataObject";
import useTypes from "../../../../../../../Type/Api/useTypes";


export interface ProductLineQuantityProps
{
    productLine: ProductLineEditorInterface;
    showLabel?: boolean;
    alignment?: Alignment;
}

export const ProductLineQuantity: React.FC<ProductLineQuantityProps> = observer(
    ({
        productLine,
        showLabel,
        alignment,
    }) =>
    {
        const types = useTypes();

        const onBlurQuantity =
            useCallback(
                async () =>
                {
                    if (productLine.commitContext.isEntityDirty(productLine.productLine))
                    {
                        productLine.resetPrice();
                    }
                },
                [
                    productLine
                ]
            );

        return <Input
            entity={productLine.productLine}
            field={types.ProductLine.Field.Quantity}
            labelPosition={showLabel ? 'left' : 'none'}
            alignment={alignment}
            disabled={productLine.isProductAndQuantityAndPriceDisabled}
            doAutocommit={false}
            commitContext={productLine.commitContext}
            onBlur={onBlurQuantity}
            showCommitButtonOnTouch
        />
    }
)

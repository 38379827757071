import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles, Table } from "@material-ui/core";
import { Draggable as DraggableDnd } from "react-beautiful-dnd";
import DragHandlePropsContext from "../../../../../../../DragAndDrop/Context/DragHandlePropsContext";
import DraggableStateContext from "../../../../../../../DragAndDrop/Context/DraggableStateContext";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DragHandle from "../../../../../../../DragAndDrop/DragHandle/DragHandle";
import ViewGroup from "../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup";
import ViewGroupItem from "../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem";
import ProductLineSubscriptionEditor from "./ProductLineSubscriptionEditor/ProductLineSubscriptionEditor";
import useProductLine from "./Hooks/useProductLine";
import useTypes from "../../../../../../Type/Api/useTypes";
import { textSecondaryColor } from "../../../../../../../../../@Resource/Theme/Theme";
import Checkbox from "../../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox";
import MenuButton from "../../../../../../Item/MenuButton/MenuButton";
import { default as GenericInput } from "../../../../../../../../../@Future/Component/Generic/Input/Input/Input";
import { ProductLineProps } from "./DesktopProductLine";
import { ProductLineDescription } from "./Components/ProductLineDescription";
import { ProductLineQuantity } from "./Components/ProductLineQuantity";
import { ProductLineStockLevel } from "./Components/ProductLineStockLevel";
import { ProductLinePrice } from "./Components/ProductLinePrice";
import { ProductLinePurchasePrice } from "./Components/ProductLinePurchasePrice";
import { ProductLineVatGroup } from "./Components/ProductLineVatGroup";
import { ProductLineDiscountPercentage } from "./Components/ProductLineDiscountPercentage";
import { ProductLineTotal } from "./Components/ProductLineTotal";
import { ProductLineMilestone } from "./Components/ProductLineMilestone";
import { ProductLineLayoutEditor } from "./Components/ProductLineLayoutEditor";
import useIsDragging from "../../../../../../../DragAndDrop/Api/useIsDragging";
import { ProductLineExtraDescription } from "./Components/ProductLineExtraDescription";

const useStyles = makeStyles({
    underline:
    {
        fontSize: 12,
        color: textSecondaryColor
    },
    checkbox:
    {
        position: 'absolute',
        top: 0,
        left: 0
    },
    lastColumn:
    {
        paddingRight: 10
    },
    firstColumn:
    {
        paddingLeft: '7px !important',
        paddingRight: 7,
    }
});

export const MobileProductLine: React.FC<ProductLineProps> = observer(
    ({
         idx,
         entity,
         commitContext,
         onSelected,
         isSelected,
         isStrikethroughed,
         loading,
         currency,
         disabled,
         autoCommit,
         onChangeSpecificationLayout,
         showMilestone,
         hidePrices,
         isLoadingSpecificationLayout,
         specificationLayout,
         specificationLayoutParameterAssignment,
         specificationLayoutParameters
    }) =>
    {
        const types = useTypes();
        const classes = useStyles();
        const isDragging = useIsDragging();
        const model =
            useProductLine(
                entity,
                commitContext,
                onSelected,
                isSelected,
                isStrikethroughed,
                loading,
                currency,
                disabled,
                autoCommit
            );

        return <DraggableDnd
            draggableId={model.uuid}
            index={idx}
            isDragDisabled={disabled}
        >
            {
                (provided, snapshot) =>
                <DragHandlePropsContext.Provider
                    value={provided.dragHandleProps}
                >
                    <DraggableStateContext.Provider
                        value={snapshot}
                    >
                        <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                        >
                            <TableCell
                                align="left"
                                className={classes.firstColumn}
                            >
                                {
                                    onSelected && !disabled &&
                                    <Checkbox
                                        checked={isSelected}
                                        onToggle={model.onInvoice}
                                        className={classes.checkbox}
                                    />
                                }
                                <DragHandle />
                            </TableCell>
                            <TableCell
                                className={classes.lastColumn}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                >
                                    <ViewGroupItem>
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={0}
                                        >
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <ProductLineDescription
                                                    productLine={model}
                                                    showLabel
                                                    disabled={disabled}
                                                />
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <MenuButton
                                                    entity={model.productLine}
                                                >
                                                    <GenericInput
                                                        labelPosition="right"
                                                        label={types.ProductLine.Field.ExtraDescription.getName()}
                                                    >
                                                        <Checkbox
                                                            checked={model.description.isExtraDescriptionVisible}
                                                            onToggle={model.description.setExtraDescriptionVisible}
                                                        />
                                                    </GenericInput>
                                                </MenuButton>
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </ViewGroupItem>
                                    {
                                        model.description.isExtraDescriptionVisible &&
                                        <ViewGroupItem>
                                            <ProductLineExtraDescription
                                                productLine={model}
                                                disabled={disabled}
                                            />
                                        </ViewGroupItem>
                                    }
                                    <ViewGroupItem>
                                        <ProductLineQuantity
                                            productLine={model}
                                            showLabel
                                        />
                                    </ViewGroupItem>
                                    <ProductLineStockLevel
                                        productLine={model}
                                    />
                                    {
                                        !hidePrices &&
                                        <ViewGroupItem>
                                            <ProductLinePrice
                                                productLine={model}
                                                autoCommit={autoCommit}
                                                currency={currency}
                                                showLabel
                                            />
                                        </ViewGroupItem>
                                    }
                                    {
                                        !hidePrices &&
                                        <ViewGroupItem>
                                            <ProductLinePurchasePrice
                                                productLine={model}
                                                autoCommit={autoCommit}
                                                disabled={disabled}
                                                showLabel
                                            />
                                        </ViewGroupItem>
                                    }
                                    {
                                        !hidePrices &&
                                        <ViewGroupItem>
                                            <ProductLineVatGroup
                                                productLine={model}
                                                autoCommit={autoCommit}
                                                showLabel
                                            />
                                        </ViewGroupItem>
                                    }
                                    {
                                        !hidePrices &&
                                        <ViewGroupItem>
                                            <ProductLineDiscountPercentage
                                                productLine={model}
                                                autoCommit={autoCommit}
                                                showLabel
                                            />
                                        </ViewGroupItem>
                                    }
                                    {
                                        !hidePrices &&
                                        <ViewGroupItem>
                                            <ProductLineTotal
                                                productLine={model}
                                                autoCommit={autoCommit}
                                                currency={currency}
                                                showLabel
                                            />
                                        </ViewGroupItem>
                                    }
                                    {
                                        showMilestone &&
                                        <ViewGroupItem>
                                            <ProductLineMilestone
                                                productLine={model}
                                                disabled={disabled}
                                                autoCommit={autoCommit}
                                                showLabel
                                            />
                                        </ViewGroupItem>
                                    }
                                    {
                                        model.isSubscriptionLine &&
                                        <ViewGroupItem>
                                            <ProductLineSubscriptionEditor
                                                entity={model.productLine}
                                                disabled={disabled}
                                                commitContext={model.commitContext}
                                            />
                                        </ViewGroupItem>
                                    }
                                    <ViewGroupItem>
                                        <Table>
                                            <ProductLineLayoutEditor
                                                productLine={model}
                                                isDragging={isDragging}
                                                onChangeSpecificationLayout={onChangeSpecificationLayout}
                                                isLoadingSpecificationLayout={isLoadingSpecificationLayout}
                                                specificationLayout={specificationLayout}
                                                onSelected={onSelected}
                                                specificationLayoutParameterAssignment={specificationLayoutParameterAssignment}
                                                showMilestone={showMilestone}
                                                specificationLayoutParameters={specificationLayoutParameters}
                                                isSpecificationLayoutInEditMode={false}
                                            />
                                        </Table>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </TableCell>
                        </TableRow>
                    </DraggableStateContext.Provider>
                </DragHandlePropsContext.Provider>
            }
        </DraggableDnd>
    }
);